import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingsService } from "../services/settingsService";
import { useNavigate, useParams } from "react-router-dom";


// get last login
export const getLastLogin = createAsyncThunk(
    "/lastLogin/get",
    async (thunkAPI) => {
      try {
        return await settingsService.fetchLastLogin();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

  // get all active sessions
export const getActiveSessions = createAsyncThunk(
    "/activeSessions/get",
    async (thunkAPI) => {
      try {
        return await settingsService.getActiveSessions();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

  //reset all active sessions, excluding the one you are using
export const resetSessions = createAsyncThunk(
    "/resetActiveSessions/get",
    async (thunkAPI) => {
      try {
        return await settingsService.resetActiveSessions();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

  //update password (set new password)
export const updatePass = createAsyncThunk(
    "/update-password/put",
    async (data, thunkAPI) => {
      try {
        return await settingsService.updatePass(data.data.values);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

  // get last login
export const verifyUserRegistrationCode = createAsyncThunk(
  "/verify-code/get",
  async (thunkAPI) => {
    try {
      return await settingsService.verifyUserRegistrationCode();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const {email, code} = useParams();
  navigate(`/userdetails/learn/verify-user/verify-user/${email}/${code}`)

};

  //set new password for new account
  export const setPassword = createAsyncThunk(
    "/seNewPass/post",
    async (data, thunkAPI) => {
      try {
        return await settingsService.setPassword(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

    //set new password for new account
    export const resetPasswordEmail = createAsyncThunk(
      "/reset-password/post",
      async (data, thunkAPI) => {
        try {
          return await settingsService.resetPasswordEmail(data);
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message;
          return thunkAPI?.rejectWithValue(message);
        }
      }
    );

      //reset password
  export const resetPassword = createAsyncThunk(
    "/resetPassword/post",
    async (data, thunkAPI) => {
      try {
        return await settingsService.resetPassword(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

  const settingsSlice = createSlice({
    name: "settings",
    initialState: {
      loginData: null,
      activeSessionsData: null,
      registrationCode:null,
      loading: false,
      error: null,
      status: null,
      setPassLoading:false,
      setPassStatus:null,
      setPasswordMessage:null,
      resetPasswordLoading:null,
      resetPasswordMessage:null,
      resetPasswordStatus:null, 
    message: null
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getLastLogin.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getLastLogin.fulfilled, (state, action) => {
          state.loading = false;
          state.loginData = action.payload;
        })
        .addCase(getLastLogin.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(getActiveSessions.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(getActiveSessions.fulfilled, (state, action) => {
            state.loading = false;
            state.activeSessionsData = action.payload;
          })
          .addCase(getActiveSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          })
          .addCase(updatePass.fulfilled, (state, action) => {
            if (action.payload.code === 200) { 
              state.status = 'succeeded';
              state.message = action.payload.data.message || 'uspesni';
            } else {
              state.status = 'failed';
              state.message = 'Došlo je do greške, pokušajte ponovo.';
            }
          })
          .addCase(updatePass.rejected, (state, action) => {
            state.status = 'failed';
            state.message = action.payload?.errors || 'Greška pri ažuriranju lozinke';
          })
          .addCase(updatePass.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(verifyUserRegistrationCode.fulfilled, (state, action) => {
            if (action.payload.code === 200) { 
              useCustomNavigate();
            } else {
              state.status = 'failed';
              state.message = 'Došlo je do greške, pokušajte ponovo.';
            }
          })
          .addCase(verifyUserRegistrationCode.rejected, (state, action) => {
            state.status = 'failed';
            state.message = action.payload?.errors || 'Greška pri ažuriranju lozinke';
          })
          .addCase(verifyUserRegistrationCode.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(setPassword.fulfilled, (state, action) => {
            if (action.payload.code === 200) { 
              state.setPassStatus = 'succeeded';
              state.setPasswordMessage = action.payload.data.message || 'uspesni';
            } else {
              state.setPassStatus = 'failed';
              state.setPasswordMessage = 'Došlo je do greške, pokušajte ponovo.';
            }
          })
          .addCase(setPassword.rejected, (state, action) => {
            state.setPassStatus = 'failed';
            state.setPasswordMessage = action.payload?.errors || 'Greška pri ažuriranju lozinke';
          })
          .addCase(setPassword.pending, (state) => {
            state.setPassStatus = 'loading';
          })
          .addCase(resetPasswordEmail.fulfilled, (state, action) => {
            if (action.payload.code === 200) { 
              state.resetPasswordStatus = 'succeeded';
              state.resetPasswordMessage = action.payload.data.message || 'uspesni';
            } else {
              state.resetPasswordStatus = 'failed';
              state.resetPasswordMessage = 'Uneli ste nepostojeći mail.';
            }
          })
          .addCase(resetPasswordEmail.rejected, (state, action) => {
            state.resetPasswordStatus = 'failed';
            state.resetPasswordMessage = action.payload?.errors || 'Uneli ste nepostojeći mail.';
          })
          .addCase(resetPasswordEmail.pending, (state) => {
            state.resetPasswordStatus = 'loading';
          })
          .addCase(resetPassword.fulfilled, (state, action) => {
            if (action.payload.code === 200) { 
              state.status = 'succeeded';
              state.message = action.payload.data.message || 'uspesni';
            } else {
              state.status = 'failed';
              state.message = 'Došlo je do greške, pokušajte ponovo.';
            }
          })
          .addCase(resetPassword.rejected, (state, action) => {
            state.status = 'failed';
            state.message = action.payload?.errors || 'Greška pri ažuriranju lozinke';
          })
          .addCase(resetPassword.pending, (state) => {
            state.status = 'loading';
          })
    },
  });
  
  export default settingsSlice.reducer;