import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/ModalContext";
import { getMembers, resetStatusCreateUser } from "../../api/slices/memberSlice";
import { useEffect } from "react";
import EmployeesTable from "../common/tables/EmployeesTable";
import PopupSuccess from "../modals/PopupSuccess";
import Loader from "../common/Loader";

const Employees = () => {
  const dispatch = useDispatch();
  const { membersData, status, message, loading } = useSelector(
    (state) => state.members
  );

  useEffect(() => {
    //reset status - create user
    dispatch(resetStatusCreateUser()); 
  }, [dispatch]); 


  const { openModal } = useModal();
  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);


  if (loading || !membersData) {
    return <Loader />;
  }
  const headerNamesMapper = {
    id: "ID",
    firstName: "Ime",
    lastName: "Prezime",
    roleName: "Rola",
    active: "Status",
  };

  // Display names for headers
  const headers = ["id", "firstName", "lastName", "roleName", "active"];

  //Trigger this function in case that deletation of team member is confirmed
  // const confirmDelete = (item) => {
  //   console.log("Confirm" + item);
  // };

  //Hardcoded values for user roles
  const options = [
    { value: 1, label: "Administrator" },
    { value: 2, label: "Student" },
  ];

  return (
    <div className="flex flex-col w-full h-full p-4 ">
      <div className="px-6">
        {/* <Table
            headers={headers}
            data={data}
            buttons={buttons}
            headerNames={headerNames}
            role={userRole}
            searchText="listu zaposlenih"
            tableTitle="Članovi moje organizacije"
          /> */}
        <EmployeesTable
          data={membersData?.data?.employees}
          headers={headers}
          headerNamesMapper={headerNamesMapper}
          button={["trash", "sendMessage"]}
          itemsPerPage="10"
          searchText="Imenu zaposlenog"
          componentType="employees"
        />

        <div className="py-3">
          <button
            onClick={() =>
              openModal("ModalAdd", {
                content: [
                  { name: "firstName", label: "Ime", type: "text" },
                  { name: "lastName", label: "Prezime", type: "text" },
                  { name: "roleId", label: "Rola", type: "dropDown" },
                  { name: "username", label: "Korisničko ime", type: "text" },
                  { name: "email", label: "Email", type: "email" },
                ],
                options: options,
                type: "createUser",
              })
            }
            className="bg-primary-800 dark:bg-primary-800 button-success hover:bg-gray-700 dark:hover:bg-gray-700"
          >
            Dodaj korisnika
          </button>
          {status === "succeeded" && (
            <PopupSuccess label="Uspešno kreiran korisnik!" type="success" />
          )}
          {status === "failed" && (
            <PopupSuccess
              label="Došlo je do greške, pokušajte ponovo."
              type="error"
            />
          )}
          {status === "loading" && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Employees;
