import { useState, React } from "react";
import { useModal } from "../../../context/ModalContext";
import { useDispatch } from "react-redux";
import SearchBar from "../SearchBar";
import TableRecursiveRender from "./TableRecursiveRender";
import { EnvelopeIcon, UserMinusIcon } from "@heroicons/react/24/outline";
import Pagination from "../Pagination";
import { deactivateUser, getMembers } from "../../../api/slices/memberSlice";
import BulkDeletePopup from "../../modals/BulkDeletePopup";
import EmployeesSideBar from "../EmployeesSideBar";
import SendMessage from "../../course_components/SendMessage";

const EmployeesTable = ({
  data,
  itemsPerPage,
  headers,
  headerNamesMapper,
  button = [],
  searchText,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { openModal, closeModal } = useModal();
  const [selectedRow, setSelectedRow] = useState(null);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [popupData, setPopupData] = useState(null); 

  const dispatch = useDispatch();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = data?.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  

  const handleSearchChange = (e) => {
    setCurrentPage(1);
    setSearchQuery(e.target.value);
  };
  const handleRowClick = (item) => {
    setSelectedRow(item);
    setOpenSideBar(true);
  };

  const handleCheckboxChange = (id, email) => {
    setSelectedItems((prev) => {
      const exists = prev.find((item) => item.id === id);
      if (exists) {
        return prev.filter((item) => item.id !== id);
      } else {
        return [...prev, { id, email }];
      }
    });
  };

  const handleAllCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedItems(
        currentItems.map((item) => ({ id: item.id, email: item.email }))
      );
    } else {
      setSelectedItems([]);
    }
  };

  const openPopupWithSelectedItems = () => {
    setPopupData(selectedItems);
  };

  const openPopupWithSingleItem = (id, email) => {
    setPopupData([{ id, email }]);
  };
  const resetSelectedItems = () => {
    setSelectedItems([]);
  };

  return (
    <>
      <div className="relative overflow-x-auto sm:rounded-lg">
        <div className="flex items-center justify-between py-3 mr-1 lg:justify-between ">
          <div className="w-4 h-4">
            {selectedItems?.length > 0 && (
              <button
                onClick={openPopupWithSelectedItems}
                className="fixed px-4 py-2 text-white rounded-md shadow-md z-50 bg-primary-800 bottom-5 right-5 hover:bg-primary-750"
              >
                Pošalji obaveštenje ({selectedItems.length})
              </button>
            )}
          </div>

          <SearchBar
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
            type="search"
            searchBy={searchText}
            position="left"
          />
        </div>

        <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-primary-800 dark:text-gray-400">
            <tr>
              <td className="flex justify-center pt-4 pl-4">
                <input
                  type="checkbox"
                  onChange={handleAllCheckboxChange}
                  checked={
                    currentItems?.every((item) =>
                      selectedItems.some((sel) => sel.id === item.id)
                    ) && selectedItems.length > 0
                  }
                ></input>
              </td>
              {headers?.map((header, index) => (
                <th
                  key={index}
                  className="px-6 py-3 text-center cursor-pointer"
                >
                  {headerNamesMapper[header]}
                </th>
              ))}

              <td className="flex items-center px-3 py-3">
                <p className="font-bold">Akcije</p>
              </td>
            </tr>
          </thead>

          <tbody>
            {currentItems?.map((item, rowIndex) => (
              <tr
                key={rowIndex}
                className={`border-b cursor-pointer dark:hover:bg-primary-800 bg-gray-50 dark:bg-primary-750 dark:border-primary-800 hover:bg-slate-200`}
                onClick={() => handleRowClick(item)}
             >
                <td className="flex justify-center pt-4 pl-4">
                  <input
                    type="checkbox"
                    onClick={(e) => e.stopPropagation()}
                    checked={selectedItems.some((sel) => sel.id === item.id)}
                    onChange={() => handleCheckboxChange(item.id, item.email)}
                  />
                </td>
                {headers?.map((header) => {
                  let data;
                  switch (header) {
                    case "roleName":
                      data = item["role"]?.roleName;
                      break;
                    case "active":
                      data = item["status"] ? "Aktivan nalog" : "Deaktiviran nalog";
                      break;
                    default:
                      data = item[header];
                  }
                  return (
                    <TableRecursiveRender data={data} elem="title" />
                  );
                })}
                <td className="flex justify-between px-6 py-4 text-center">
                  {button.map((action) => (
                    <>
                      {action === "sendMessage" && (
                        <EnvelopeIcon
                          className="w-5 h-5"
                          onClick={(e) => {
                            e.stopPropagation(); 
                            openPopupWithSingleItem(item.id, item.email); 
                          }}
                        />
                      )}
                       {action === "trash" && (
                        <UserMinusIcon
                          className="w-5 h-5 mx-1 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            openModal("Modal1", {
                              text: "Da li ste sigurni da želite da deaktivirate korisnika?",
                              close: closeModal,
                              confirm: () => {
                                dispatch(
                                  deactivateUser({ ids: [item.id] })
                                ).then(() => dispatch(getMembers()));
                              },
                            });
                          }}
                        />
                      )}
                    </>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {filteredData?.length > itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {openSideBar && selectedRow && (
        <EmployeesSideBar
          handleClose={() => setOpenSideBar(null)}
          data={selectedRow}
        />
      )}
      {popupData && (
        <SendMessage
          data={popupData}
          onClose={() => {
            setPopupData(null);
            resetSelectedItems(); 
          }}
        />
      )}

    </>
  );
};

export default EmployeesTable;
