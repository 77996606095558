import React from "react";
import TableRowSelection from "../../common/tables/TableRowSelection";
import { useLocation } from "react-router-dom";

const headers = [
  {
    name: "ID",
    key: "user.id",
    sortable: false,
    filter: false,
  },
  {
    name: "Ime",
    key: "user.firstName",
    sortable: false,
    filter: false,
  },
  {
    name: "Prezime",
    key: "user.lastName",
    sortable: true,
    filter: false,
  },
  { name: "Rola", key: "user.roleName", sortable: false, filter: false },
  { name: "Status", key: "passed", sortable: false, filter: true },

  
];

//number of columns in pagination
const itemsPerPage = 10;
//search hint "pretraga po"
const searchText = "Ime studenta";

const UserCourses = () => {
  //passed data
  const location = useLocation();
  const testResultsMap = location.state?.testResultsMap;

  return (
    <>
      <TableRowSelection
        data={testResultsMap}
        headers={headers}
        itemsPerPage={itemsPerPage}
        searchText={searchText}
        button=""
        useSearch={true} // if value is true SearchBar will show
        getDetails={true} // if value is true, on row click SideBar with details will be opened
      />
    </>
  );
};

export default UserCourses;
