import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSidebar } from "../../context/SidebarContext";
import {
  ChevronLeftIcon,
  ArrowLeftOnRectangleIcon,
  PresentationChartLineIcon,
  AcademicCapIcon,
  Cog8ToothIcon,
  ChartBarSquareIcon,
  PencilSquareIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../api/slices/authSlice";

const Sidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const [isExpanded, setIsExpanded] = useState(true);
  const { roleName: userRole } = useSelector((state) => state.auth.user?.role);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`relative top-0 flex bg-primary-800   ${
          !isExpanded ? "animate-left " : " animate-right lg:w-1/6"
        }`}
      >
        {/* End of hamburger menu */}
        {/* Sidebar */}
        <aside
          id="sidebar"
          className={` min-h-screen h-full lg:w-full  bg-primary-800 text-white text-opacity-75 dark:bg-800  ${
            isSidebarOpen
              ? "animate-slide-in-left block absolute top-0 left-0 z-20"
              : " max-lg:hidden"
          } `}
        >
          <div className="flex px-3 py-8 text-xl text-center ">
            <div className="flex items-center justify-between w-full">
              <span
                className={`${
                  isExpanded ? "block" : "hidden"
                } text-white  mx-auto lg:text-xl sm:text-sm md:text-xs`}
              >
                C O M P L I T
              </span>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={`w-6 h-6 text-xs rounded-full lg:visible invisible z-30 ${
                  !isExpanded &&
                  "rotate-180 origin-center absolute left-9 top-10"
                }`}
              >
                <ChevronLeftIcon />
              </button>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                toggleSidebar();
              }}
              className={`${isSidebarOpen ? "block " : "hidden"}`}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 8 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"
                />
              </svg>
            </button>
          </div>
          <div className={`flex-1 px-4 lg:mt-7 lg:px-6`}>
            <p
              className={`mt-10 ${
                isExpanded ? "animate-text" : "hidden"
              } font-bold`}
            >
              Glavni meni
            </p>

            {userRole === "admin" && (
              <>
                <div>
                  <Link to="/dashboard/my-dashboard">
                    <div className="flex gap-5 sidebar-button">
                      <span className="flex-shrink-0 w-7">
                        <ChartBarSquareIcon />
                      </span>
                      <div
                        className={`mt-1 ${
                          isExpanded ? "animate-text" : "hidden"
                        }`}
                      >
                        Dashboard
                      </div>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to="/dashboard/employees">
                    <div className="flex gap-5 sidebar-button">
                      <span className="flex-shrink-0 w-7">
                        <UserGroupIcon />
                      </span>
                      <div
                        className={`mt-1 ${
                          isExpanded ? "animate-text" : "hidden"
                        }`}
                      >
                        Zaposleni
                      </div>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to="/dashboard/all-courses">
                    <div className="flex items-center gap-5 sidebar-button">
                      <span className="flex-shrink-0 w-7">
                        <PresentationChartLineIcon />
                      </span>
                      <div
                        className={`mt-1 transition-opacity duration-300 ${
                          isExpanded ? "animate-text" : "hidden"
                        }`}
                      >
                        Svi testovi
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div>
                  <Link to="/dashboard/users-courses">
                    <div className="flex items-center gap-5 sidebar-button">
                      <span className="flex-shrink-0 w-7">
                        <PresentationChartLineIcon />
                      </span>
                      <div
                        className={`mt-1 transition-opacity duration-300 ${
                          isExpanded ? "block" : "hidden"
                        }`}
                      >
                        Kursevi korisnika
                      </div>
                    </div>
                  </Link>
                </div> */}
              </>
            )}

            {/* <div>
    <Link to="/dashboard/courses">
      <div className="flex items-center gap-5 sidebar-button">
        <span className="flex-shrink-0 w-7">
          <PresentationChartLineIcon />
        </span>
        <div
          className={`mt-1 transition-opacity duration-300 ${
            isExpanded ? "animate-text" : "hidden"
          }`}
        >
          Moji kursevi
        </div>
      </div>
    </Link>
  </div> */}
            {userRole === "student" && (
              <div>
                <Link to="/dashboard/tests">
                  <div className="flex items-center gap-5 sidebar-button">
                    <span className="flex-shrink-0 w-7">
                      <PencilSquareIcon />
                    </span>
                    <div
                      className={`mt-1 transition-opacity duration-300 ${
                        isExpanded ? "animate-text" : "hidden"
                      }`}
                    >
                      Moji testovi
                    </div>
                  </div>
                </Link>
              </div>
            )}
            <p
              className={`mt-8 ${
                isExpanded ? "animate-text" : "hidden"
              } font-bold`}
            >
              Podrška
            </p>

            <div>
              <Link to="/dashboard/settings">
                <div className="flex items-center gap-5 sidebar-button">
                  <span className="flex-shrink-0 w-5 lg:w-7 md:w-5">
                    <Cog8ToothIcon />
                  </span>
                  <div
                    className={`mt-1 ${
                      isExpanded
                        ? "animate-text lg:text-base text-sm md:text-sm"
                        : "hidden"
                    }`}
                  >
                    Podešavanja
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 px-4 mb-4 align-bottom lg:mt-7 lg:px-6">
            <Link to="">
              <div className="flex gap-5 sidebar-button ">
                <span className="flex-shrink-0 w-7 ">
                  <ArrowLeftOnRectangleIcon />
                </span>
                <div
                  className={`mt-1 ${isExpanded ? "animate-text" : "hidden"}`}
                  onClick={() => dispatch(logout())}
                >
                  Odjavi se
                </div>
              </div>
            </Link>
          </div>
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
